import { useCallback, useMemo } from 'react';
import Store from '@mui/icons-material/Store';
import { useTheme } from '@mui/material/styles';
import Identity from '@mui/icons-material/PermIdentity';
import useMediaQuery from '@mui/material/useMediaQuery';

import { useAppDispatch, useAppSelector } from '@/store/app';
import {
  AddProductButton,
  IAddProductActionButtonProps,
} from '@/components/mui/add-product-button';
import {
  selectUserShop,
  selectHasUserShop,
  updateDraftProduct,
} from '@/store/domains';
import { useSameRouteNavigate } from '@/hooks/use-navigate';

export const AppProfile = () => {
  const theme = useTheme();
  const dispatch = useAppDispatch();
  const navigate = useSameRouteNavigate();

  const userShop = useAppSelector(selectUserShop);
  const hasUserShop = useAppSelector(selectHasUserShop);
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'));

  const handleOnClick = useCallback(
    () => userShop && navigate({ merge: { shopId: userShop.id } }),
    [userShop, navigate],
  );

  const onActionClicked = useCallback(() => {
    dispatch(updateDraftProduct({}));
    navigate({ merge: { productTab: 'add-product' } });
  }, [dispatch, navigate]);

  const getButtonProps = useCallback(():
    | IAddProductActionButtonProps
    | undefined => {
    if (hasUserShop) {
      if (isMobile) {
        return {
          Icon: Identity,
          color: 'primary',
          variant: 'circular',
        };
      }
      return {
        Icon: Store,
        color: 'primary',
        variant: 'extended',
      };
    }

    return;
  }, [isMobile, hasUserShop]);

  const label = useMemo(
    () => (isMobile ? undefined : userShop?.name ?? 'Додати оголошення'),
    [isMobile, userShop],
  );

  if (!hasUserShop) {
    return (
      <AddProductButton
        getButtonProps={getButtonProps}
        onClick={onActionClicked}
        location="map"
        label={label}
      />
    );
  }

  return (
    <AddProductButton
      label={label}
      location="map"
      onClick={handleOnClick}
      getButtonProps={getButtonProps}
    />
  );
};
