import React from 'react';
import Image from 'next/image';
import Box, { BoxProps } from '@mui/material/Box';
import Typography from '@mui/material/Typography';

const logoText = 'єБазар';

export type ILogoProps = BoxProps;

export function Logo(props: ILogoProps) {
  return (
    <Box
      {...props}
      sx={{
        ...props.sx,
        display: 'flex',
        alignItems: 'center',
      }}
    >
      <Typography variant="h5">{logoText}</Typography>
      <Box
        sx={{
          width: '27px',
          height: '35px',
          '& > *': { transform: 'rotate(-25deg) translateY(4px)' },
        }}
      >
        <Image
          src="/images/logo.png"
          unoptimized
          priority
          alt="logo"
          width={26.25}
          height={26.25}
        />
      </Box>
    </Box>
  );
}
