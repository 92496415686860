import React from 'react';
import { useTheme } from '@mui/material/styles';
import Button, { ButtonProps } from '@mui/material/Button';

import { Logo } from '@/components/mui/logo';
import { FlatButton, IFlatButtonProps } from '@/components/mui/flat-button';

export type ILogoButtonProps = ButtonProps;

export function LogoButton(props: ILogoButtonProps) {
  const theme = useTheme();
  return (
    <Button
      color="secondary"
      {...props}
      sx={{
        boxShadow: 6,
        borderRadius: 3,
        textTransform: 'none',
        p: theme.spacing(0.625, 1.875),
        backgroundColor: theme.palette.background.paper,
        ['&:hover']: {
          backgroundColor: theme.palette.background.paper,
        },
        ...props.sx,
      }}
    >
      <Logo />
    </Button>
  );
}

export type IFlatLogoButtonProps = IFlatButtonProps;

export function FlatLogoButton(props: IFlatLogoButtonProps) {
  const theme = useTheme();
  return (
    <FlatButton
      {...props}
      color="secondary"
      variant="outlined"
      sx={{
        p: theme.spacing(1, 2),
        borderRadius: 3,
        textTransform: 'none',
        ...props.sx,
      }}
    >
      <Logo />
    </FlatButton>
  );
}
