import React from 'react';
import Stack, { StackProps } from '@mui/material/Stack';
import Link from '@mui/material/Link';

interface IInfoLink {
  text: string;
  href: string;
}

const links: IInfoLink[] = [
  {
    text: 'Про проєкт ↗',
    href: 'http://about.e-bazar.ua/',
  },
  {
    text: 'Правила користування ↗',
    href: 'https://e-bazar.notion.site/e-bazar/ec5904734f504149a46f499fed62e33c',
  },
  {
    text: 'Написати нам в Телеграм ↗',
    href: 'https://t.me/eBazarSupport',
  },
];

export type IInfoLinks = StackProps;

export function InfoLinks(props: IInfoLinks) {
  return (
    <Stack spacing={2} {...props}>
      {links.map(({ text, href }, index) => (
        <Link
          key={index}
          href={href}
          target="_blank"
          referrerPolicy="no-referrer"
        >
          {text}
        </Link>
      ))}
    </Stack>
  );
}
