import React from 'react';
import { useTheme } from '@mui/material/styles';
import SwipeableDrawer from '@mui/material/SwipeableDrawer';

import { useOpenState } from '@/hooks/use-open-state';
import { iOSMobile } from '@/utils/iOS';

import { LogoButton, ILogoButtonProps } from '../logo-button';
import { InfoSection } from '../info-section';

export interface IInfoSideBarProps {
  logoButtonProps?: ILogoButtonProps;
  footer?: React.ReactElement<{ onClick: () => void }>;
}

export function InfoSideBar({ logoButtonProps, footer }: IInfoSideBarProps) {
  const theme = useTheme();
  const { open, onOpen, onClose } = useOpenState();

  return (
    <>
      <LogoButton
        {...logoButtonProps}
        sx={{
          position: 'absolute',
          zIndex: 1,
          left: 0,
          top: 0,
          m: 3,
          ...logoButtonProps?.sx,
          [theme.breakpoints.down('sm')]: {
            m: 2,
          },
        }}
        onClick={onOpen}
      />
      <SwipeableDrawer
        anchor="left"
        open={open}
        onOpen={onOpen}
        onClose={onClose}
        disableDiscovery={iOSMobile}
        disableBackdropTransition={!iOSMobile}
      >
        <InfoSection
          onLogoClick={onClose}
          footer={
            footer
              ? React.cloneElement(footer, { onClick: onClose })
              : undefined
          }
          sx={{
            height: '100%',
            width: 360,
            maxWidth: `calc(100% - ${theme.spacing(5)})`,
          }}
        />
      </SwipeableDrawer>
    </>
  );
}
