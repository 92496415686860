import React, { useCallback } from 'react';
import Button from '@mui/material/Button';
import { useTheme } from '@mui/material/styles';

import { cutString } from '@/utils/string';
import { useSameRouteNavigate } from '@/hooks/use-navigate';
import type { IShopClusterData } from '@/types/map';

export interface IShopMarkerProps {
  isActive?: boolean;
  shop: IShopClusterData;
  map?: google.maps.Map;
  lat: number;
  lng: number;
}

export function ShopMarker({
  isActive,
  shop,
  map,
  lat,
  lng,
}: IShopMarkerProps) {
  const theme = useTheme();

  const navigate = useSameRouteNavigate();
  const onClick = useCallback(() => {
    map?.panTo({ lat, lng });
    requestIdleCallback(() =>
      navigate({
        merge: { shopId: shop.id },
        omit: ['productId', 'productTab'],
      }),
    );
  }, [lat, lng, map, navigate, shop.id]);

  return (
    <Button
      size="small"
      color="secondary"
      variant="contained"
      sx={{
        boxShadow: 5,
        whiteSpace: 'nowrap',
        textTransform: 'none',
        ...(isActive && {
          zIndex: 1,
        }),
        ...(!isActive && {
          color: theme.palette.secondary.main,
          backgroundColor: theme.palette.background.paper,
        }),
        ['&:hover']: {
          color: theme.palette.background.paper,
          backgroundColor: theme.palette.primary.main,
        },
      }}
      onClick={onClick}
    >
      {cutString(shop.name.trim(), 20)}
    </Button>
  );
}
