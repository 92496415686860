import React, {
  useMemo,
  useState,
  useCallback,
  useRef,
  useEffect,
} from 'react';
import GoogleMapReact, {
  BootstrapURLKeys,
  ChangeEventValue,
  MapOptions,
  Bounds,
} from 'google-map-react';
import getConfig from 'next/config';
import { Theme, useTheme } from '@mui/material/styles';

import { Loader } from '@/components/mui/loader';
import { FullScreenBox } from '@/components/mui/full-screen-box';
import {
  LANGUAGE_CODE,
  MAX_MAP_ZOOM,
  MIN_MAP_ZOOM,
  GOOGLE_API_LIBRARIES,
  UKRAINE_COORDINATES,
  DEFAULT_MAP_ZOOM_COUNTRY,
} from '@/utils/constants';
import { useFetchShopsAndProducts } from '@/hooks/use-fetch-shop-and-products';
import { calculateDistance, toLatLng } from '@/utils/geo';
import type {
  SingleShopClusterPoint,
  MultipleShopClusterPoint,
} from '@/types/map';
import { useAppSelector } from '@/store/app';
import { selectClustersList } from '@/store/domains';
import { selectShopIdQueryParam } from '@/store/services';
import {
  isShopClusterPoint,
  isSingleShopClusterPoint,
  isMultipleShopClusterPoint,
} from '@/utils/clusters';

import { LoadingIndicator, useLoadingIndicator } from './loading-indicator';
import { MyLocationButton } from './my-location-button';
import {
  ShopMarker,
  ShopsClusterMarker,
  ShopsGroupMarker,
} from '../map-markers';

const {
  publicRuntimeConfig: { GOOGLE_MAPS_API_KEY, GOOGLE_MAP_ID },
} = getConfig();

const bootstrapURLKeys: BootstrapURLKeys = {
  id: GOOGLE_MAP_ID,
  key: GOOGLE_MAPS_API_KEY,
  language: LANGUAGE_CODE,
  libraries: GOOGLE_API_LIBRARIES,
};

const getMapOptions = (theme: Theme): MapOptions => ({
  mapId: GOOGLE_MAP_ID,

  fullscreenControl: false,
  mapTypeControl: false,
  panControl: false,
  rotateControl: false,
  scaleControl: false,
  streetViewControl: false,

  minZoom: MIN_MAP_ZOOM,
  maxZoom: MAX_MAP_ZOOM,

  zoomControl: true,
  clickableIcons: false,
  keyboardShortcuts: false,
  disableDoubleClickZoom: true,

  backgroundColor: theme.palette.background.default,
});

export interface IMapScreenProps {
  zoom?: number;
  center?: ICoordinates;
  preloadedZoom?: number;
  preloadedCenter?: ICoordinates;
  onZoomChange: (zoom: number) => void;
  onBoundsChange: (bounds: Bounds) => void;
  onDistanceChanged: (distance: number) => void;
  onCoordinatesChange: (coordinates: ICoordinates) => void;
}

export function MapScreen({
  zoom,
  center,
  preloadedZoom,
  preloadedCenter,
  onZoomChange,
  onBoundsChange,
  onDistanceChanged,
  onCoordinatesChange,
}: IMapScreenProps) {
  const [isLoaded, setIsLoaded] = useState(false);
  const [areTilesLoaded, setAreTilesLoaded] = useState(false);

  const mapRef = useRef<google.maps.Map>();
  const isMapReady = isLoaded && areTilesLoaded;
  const locationLoaded = zoom && center;

  const onLoad = useCallback(
    ({ map }) => {
      mapRef.current = map;
      setIsLoaded(true);

      const center = mapRef.current?.getCenter();
      const bounds = mapRef.current?.getBounds();

      if (center && bounds) {
        const ne = bounds.getNorthEast().toJSON();
        const sw = bounds.getSouthWest().toJSON();
        onBoundsChange({
          ne,
          sw,
          nw: { lat: ne.lat, lng: sw.lng },
          se: { lat: sw.lat, lng: ne.lng },
        });
        onDistanceChanged(calculateDistance(bounds.getNorthEast(), center));
      }
    },
    [onDistanceChanged, onBoundsChange],
  );

  const onTilesLoaded = useCallback(() => {
    setAreTilesLoaded(true);
  }, []);

  useEffect(() => {
    if (!mapRef.current || !zoom) return;
    const currentZoom = mapRef.current.getZoom();

    if (zoom !== currentZoom) {
      mapRef.current.setZoom(zoom);
    }
  }, [zoom, isMapReady, locationLoaded]);

  useEffect(() => {
    if (!mapRef.current || !center) return;
    const currentCenter = mapRef.current.getCenter();

    if (
      center.lat !== currentCenter?.lat() ||
      center.lng !== currentCenter?.lng()
    ) {
      mapRef.current.panTo(center);
    }
  }, [center, isMapReady, locationLoaded]);

  const onChange = useCallback(
    ({ zoom, center, bounds }: ChangeEventValue) => {
      if (locationLoaded) {
        onZoomChange(zoom);
        onBoundsChange(bounds);
        onCoordinatesChange(center);
      }

      if (isMapReady) {
        onDistanceChanged(calculateDistance(bounds.ne, center));
      }
    },
    [
      locationLoaded,
      isMapReady,
      onZoomChange,
      onBoundsChange,
      onCoordinatesChange,
      onDistanceChanged,
    ],
  );

  const theme = useTheme();
  const mapOptions = useMemo(() => getMapOptions(theme), [theme]);

  useFetchShopsAndProducts();
  const clusters = useAppSelector(selectClustersList);
  const activeShopId = useAppSelector(selectShopIdQueryParam);

  const { showIndicator, onShowIndicator } = useLoadingIndicator();
  const showMap = isMapReady && locationLoaded;

  return (
    <FullScreenBox>
      {!showMap && (
        <Loader
          sx={{
            zIndex: 1,
            position: 'absolute',
            backgroundColor: theme.palette.background.default,
          }}
        />
      )}
      <GoogleMapReact
        bootstrapURLKeys={bootstrapURLKeys}
        defaultZoom={preloadedZoom ?? DEFAULT_MAP_ZOOM_COUNTRY}
        defaultCenter={preloadedCenter ?? UKRAINE_COORDINATES}
        onGoogleApiLoaded={onLoad}
        onTilesLoaded={onTilesLoaded}
        onDrag={onShowIndicator}
        onZoomAnimationStart={onShowIndicator}
        onChange={onChange}
        options={mapOptions}
        debounced
      >
        {clusters?.map?.((cluster) => {
          if (isShopClusterPoint(cluster)) {
            return (
              <ShopsClusterMarker
                cluster={cluster}
                map={mapRef.current}
                key={cluster.properties.cluster_id}
                {...toLatLng(cluster.geometry.coordinates)}
              />
            );
          }

          if (isMultipleShopClusterPoint(cluster)) {
            const {
              properties: { shops },
              geometry: { coordinates },
            } = cluster as MultipleShopClusterPoint;

            return (
              <ShopsGroupMarker
                key={shops[0].id}
                cluster={cluster}
                map={mapRef.current}
                {...toLatLng(coordinates)}
              />
            );
          }

          if (isSingleShopClusterPoint(cluster)) {
            const {
              properties: { shop },
              geometry: { coordinates },
            } = cluster as SingleShopClusterPoint;

            return (
              <ShopMarker
                shop={shop}
                key={shop.id}
                map={mapRef.current}
                isActive={activeShopId === shop.id}
                {...toLatLng(coordinates)}
              />
            );
          }

          return null;
        })}
      </GoogleMapReact>
      {showMap && (
        <>
          <LoadingIndicator show={showIndicator} size="18px" color="inherit" />
          <MyLocationButton onClick={onShowIndicator} />
        </>
      )}
    </FullScreenBox>
  );
}
