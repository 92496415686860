import React from 'react';
import Box, { BoxProps } from '@mui/material/Box';
import Typography from '@mui/material/Typography';

import { FlatLogoButton } from '../logo-button';

const aboutText =
  'це просто онлайн базар продуктів домашнього виробництва. Від фермера до столу!';

export interface IAboutSectionProps extends BoxProps {
  onLogoClick?: () => void;
}

export function AboutSection({ onLogoClick, ...props }: IAboutSectionProps) {
  return (
    <Box {...props}>
      <FlatLogoButton
        onClick={onLogoClick}
        sx={{
          ml: -2,
          mb: 2,
          boxShadow: 0,
          '&:hover': { boxShadow: 0 },
          '&:focus': { boxShadow: 0 },
          '&:active': { boxShadow: 0 },
        }}
      />
      <Typography variant="body1" color="text.secondary">
        {aboutText}
      </Typography>
    </Box>
  );
}
