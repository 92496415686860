import React from 'react';
import Box, { BoxProps } from '@mui/material/Box';
import Divider from '@mui/material/Divider';

import { AboutSection } from '../about-section';
import { InfoLinks } from '../info-links';

export interface IInfoSectionProps extends BoxProps {
  onLogoClick?: () => void;
  footer?: React.ReactNode;
}

export function InfoSection({
  onLogoClick,
  footer,
  ...props
}: IInfoSectionProps) {
  return (
    <Box
      {...props}
      sx={{
        px: 4,
        py: 3,
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'stretch',
        ...props.sx,
      }}
    >
      <AboutSection onLogoClick={onLogoClick} />
      <Divider sx={{ my: 3 }} />
      <InfoLinks />
      {footer && (
        <>
          <Divider sx={{ my: 3 }} />
          <Box sx={{ mt: 'auto' }}>{footer}</Box>
        </>
      )}
    </Box>
  );
}
