import React from 'react';
import { useTheme } from '@mui/material/styles';
import Button, { ButtonProps } from '@mui/material/Button';

import { useSignOut } from '@/hooks/use-auth';
import { selectIsUserSignedIn } from '@/store/domains';
import { LinkButton } from '@/components/mui/link-button';
import { useAppSelector } from '@/store/app';
import { useLink } from '@/hooks/use-link';
import { AppRoute } from '@/route/path';

const signInButtonText = 'Реєстрація/Увійти';

function SignInButton(props: ButtonProps) {
  const signInHref = useLink(AppRoute.signIn);
  return (
    <LinkButton {...props} href={signInHref}>
      {signInButtonText}
    </LinkButton>
  );
}

const signOutButtonText = 'Вийти з аккаунту';

function SignOutButton(props: ButtonProps) {
  const onSignOut = useSignOut();
  return (
    <Button
      {...props}
      onClick={(e) => {
        onSignOut();
        props.onClick?.(e);
      }}
    >
      {signOutButtonText}
    </Button>
  );
}

export interface ISignInSignOutButton {
  onClick?: () => void;
}

export function SignInSignOutButton({ onClick }: ISignInSignOutButton) {
  const isUserSignedIn = useAppSelector(selectIsUserSignedIn);

  const theme = useTheme();
  const buttonProps: ButtonProps = {
    onClick,
    variant: 'text',
    sx: {
      textTransform: 'none',
      textDecoration: 'underline',
      ...theme.typography.body1,
      ['&:hover']: {
        textDecoration: 'underline',
      },
    },
  };

  return isUserSignedIn ? (
    <SignOutButton {...buttonProps} />
  ) : (
    <SignInButton {...buttonProps} />
  );
}
