import { styled, Theme } from '@mui/material/styles';
import Button, { ButtonProps } from '@mui/material/Button';

export type IFlatButtonProps = ButtonProps;

const createFlatStyles = (theme: Theme) => ({
  border: 'none',
  boxShadow: theme.shadows[0],
  backgroundColor: theme.palette.background.paper,
});

export const FlatButton = styled(Button)<IFlatButtonProps>(({ theme }) => {
  const flatStyles = createFlatStyles(theme);
  return {
    ...flatStyles,
    '&:hover': {
      ...flatStyles,
    },
    '&:active': {
      ...flatStyles,
    },
    '&:focus': {
      ...flatStyles,
    },
  };
});
