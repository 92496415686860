import React, { useCallback } from 'react';
import { styled } from '@mui/material/styles';
import IconButton from '@mui/material/IconButton';
import GpsFixed from '@mui/icons-material/GpsFixed';

import { useAppDispatch } from '@/store/app';
import { updateMapCoordinates } from '@/store/domains';
import { useNotification } from '@/hooks/use-notifications';
import { getBrowserGeolocation } from '@/utils/geo';

const geoDeniedMessage = 'Увімкніть доступ до геолокації на вашому пристрої.';

export interface IMyLocationButton {
  onClick?: () => void;
}

export function MyLocationButton({ onClick }: IMyLocationButton) {
  const dispatch = useAppDispatch();

  const showDenied = useNotification(geoDeniedMessage);

  const onLocationUpdate = useCallback(() => {
    onClick?.();
    getBrowserGeolocation()
      .then((coordinates) => dispatch(updateMapCoordinates(coordinates)))
      .catch(() => showDenied());
  }, [dispatch, showDenied, onClick]);

  return (
    <LocationButton size="small" onClick={onLocationUpdate}>
      <GpsFixed />
    </LocationButton>
  );
}

const LocationButton = styled(IconButton)(({ theme }) => ({
  width: '36px',
  height: '36px',
  right: '12px',
  bottom: '112px',
  borderRadius: 1,
  position: 'absolute',
  color: theme.palette.secondary.main,
  backgroundColor: theme.palette.background.paper,
  '&:hover': {
    backgroundColor: theme.palette.background.paper,
  },
}));
