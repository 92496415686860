import { isBrowser } from './is-browser';

export const getBrowserGeolocation = (): Promise<ICoordinates> =>
  new Promise((resolve, reject) => {
    if (isBrowser && 'geolocation' in window.navigator) {
      window.navigator.geolocation.getCurrentPosition(
        (position) => {
          const { latitude: lat, longitude: lng } = position.coords;
          resolve({ lat, lng });
        },
        (error) => reject(error),
        {
          enableHighAccuracy: true,
        },
      );
    } else {
      reject(new Error('Geolocation is not available'));
    }
  });

export const calculateDistance = (
  c1: google.maps.LatLng | google.maps.LatLngLiteral,
  c2: google.maps.LatLng | google.maps.LatLngLiteral,
) =>
  Math.round(
    google.maps.geometry?.spherical?.computeDistanceBetween?.(c1, c2) ?? 0,
  );

export const toLatLng = ([lng, lat]: number[]): ICoordinates => ({
  lat,
  lng,
});
