import React, { useCallback, useState } from 'react';
import Button from '@mui/material/Button';
import StorefrontIcon from '@mui/icons-material/Storefront';
import { useTheme } from '@mui/material/styles';

import { ShopMarkersMenu } from '@/components/mui/shop-markers-menu';
import { useSameRouteNavigate } from '@/hooks/use-navigate';
import { useAppSelector } from '@/store/app';
import { selectShopIdQueryParam } from '@/store/services';
import { toLatLng } from '@/utils/geo';
import type { MultipleShopClusterPoint } from '@/types/map';

export interface IShopsGroupMarkerProps {
  cluster: MultipleShopClusterPoint;
  map?: google.maps.Map;
}

export function ShopsGroupMarker({ map, cluster }: IShopsGroupMarkerProps) {
  const theme = useTheme();
  const activeShopId = useAppSelector(selectShopIdQueryParam);

  const [anchorEl, setAnchorEl] = useState<Element | null>(null);
  const onCloseMenu = () => setAnchorEl(null);

  const onClick = useCallback(
    (e: React.MouseEvent) => setAnchorEl(e.target as HTMLElement),
    [],
  );

  const navigate = useSameRouteNavigate();
  const onSelectShop = useCallback(
    (shopId: string) => {
      map?.panTo(toLatLng(cluster.geometry.coordinates));
      requestIdleCallback(() =>
        navigate({
          merge: { shopId },
          omit: ['productId', 'productTab'],
        }),
      );
    },
    [cluster.geometry.coordinates, map, navigate],
  );

  const { shops } = cluster.properties;

  return (
    <>
      <Button
        size="small"
        color="secondary"
        variant="contained"
        sx={{
          zIndex: 1,
          boxShadow: 5,
          ...(!shops.find(({ id }) => id === activeShopId) && {
            color: theme.palette.secondary.main,
            backgroundColor: theme.palette.background.paper,
          }),
          ['&:hover']: {
            color: theme.palette.background.paper,
            backgroundColor: theme.palette.primary.main,
          },
        }}
        startIcon={<StorefrontIcon />}
        onClick={onClick}
      >
        {shops.length}
      </Button>
      <ShopMarkersMenu
        open={!!anchorEl}
        anchorEl={anchorEl}
        onMouseOut={onCloseMenu}
        onCloseMenu={onCloseMenu}
        onSelectShop={onSelectShop}
        activeShopId={activeShopId}
        shops={cluster.properties.shops}
      />
    </>
  );
}
