import React from 'react';
import Link from 'next/link';
import { useTheme } from '@mui/material/styles';
import Button from '@mui/material/Button';

import { useAppSelector } from '@/store/app';
import {
  selectMapCoordinates,
  selectProductsListCount,
  selectIsProductsResourceIdle,
} from '@/store/domains';
import { getProductsCountOnMapText } from '@/utils/content-text';
import { useLink } from '@/hooks/use-link';
import { AppRoute } from '@/route/path';

export function ProductOnMapButton() {
  const theme = useTheme();

  const coordinates = useAppSelector(selectMapCoordinates);

  const productsCount = useAppSelector(selectProductsListCount);
  const isProductsResourceIdle = useAppSelector(selectIsProductsResourceIdle);

  const productsHref = useLink(AppRoute.products, {
    merge: {
      lat: coordinates?.lat.toFixed(4),
      lng: coordinates?.lng.toFixed(4),
    },
    omit: ['shopId', 'shopTab'],
  });

  if (!coordinates) {
    return null;
  }

  return (
    <Link href={productsHref} passHref shallow>
      <Button
        color="secondary"
        variant="contained"
        sx={{
          position: 'absolute',
          left: '50%',
          transform: 'translateX(-50%)',
          bottom: theme.spacing(3),
          [theme.breakpoints.down('sm')]: {
            width: '100%',
            bottom: theme.spacing(0),
            borderRadius: 0,
          },
        }}
        disabled={!productsCount}
      >
        {getProductsCountOnMapText(productsCount, isProductsResourceIdle)}
      </Button>
    </Link>
  );
}
