import { useCallback, useEffect, useState } from 'react';
import { styled } from '@mui/material/styles';

import { useAppSelector } from '@/store/app';
import { selectAreShopsLoading } from '@/store/domains';
import { Loader, ILoaderProps } from '@/components/mui/loader';

export interface ILoadingIndicatorProps extends ILoaderProps {
  show: boolean;
}

export const LoadingIndicator = styled(Loader)<ILoadingIndicatorProps>(
  ({ theme, show }) => ({
    backgroundColor: theme.palette.secondary.main,
    transition: theme.transitions.create('opacity', {
      duration: theme.transitions.duration.standard,
      easing: theme.transitions.easing.easeInOut,
    }),
    opacity: Number(show),
    color: theme.palette.background.paper,
    zIndex: 1,
    borderRadius: theme.spacing(0.5),
    position: 'absolute',
    width: '36px',
    height: '36px',
    bottom: '160px',
    right: '12px',
  }),
);

export const useLoadingIndicator = () => {
  const [showIndicator, setShowIndicator] = useState(false);

  const areShopsLoading = useAppSelector(selectAreShopsLoading);
  useEffect(() => setShowIndicator(areShopsLoading), [areShopsLoading]);

  const onShowIndicator = useCallback(() => setShowIndicator(true), []);

  return { showIndicator, onShowIndicator };
};
