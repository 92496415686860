import { useEffect, useRef } from 'react';
import { isEqual } from 'lodash';

import { useAppDispatch, useAppSelector } from '@/store/app';
import {
  selectMapZoom,
  selectMapBounds,
  selectMapCoordinates,
  fetchShopsAndProductsByLocation,
} from '@/store/domains';

type FetchAction = Promise<unknown> & {
  abort: () => void;
  arg: { zoom: number };
};

export const useFetchShopsAndProducts = () => {
  const dispatch = useAppDispatch();

  const zoom = useAppSelector(selectMapZoom);
  const bounds = useAppSelector(selectMapBounds);
  const coordinates = useAppSelector(selectMapCoordinates);

  const isFetching = useRef(false);
  const fetchAction = useRef<FetchAction | undefined>(undefined);

  useEffect(() => {
    if (!bounds || !coordinates) return;

    if (isFetching.current && fetchAction.current) {
      const { arg } = fetchAction.current;

      if (isEqual(arg, { zoom, bounds })) return;
      fetchAction.current.abort();
    }

    const frameId = requestAnimationFrame(() => {
      fetchAction.current = dispatch(
        fetchShopsAndProductsByLocation({ zoom, bounds }),
      );

      isFetching.current = true;
      fetchAction.current.finally(() => {
        isFetching.current = false;
        fetchAction.current = undefined;
      });
    });

    return () => cancelAnimationFrame(frameId);
  }, [dispatch, zoom, coordinates, bounds]);
};
