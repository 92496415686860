import React from 'react';
import type { NextPage } from 'next';

import {
  getAuthServerSideProps,
  getActiveShopServerSideProps,
  getCategoriesServerSideProps,
  getActiveShopProductsServerSideProps,
} from '@/store/server-side';
import { mergeServerSideProps } from '@/utils/merge-server-side-props';
import { AppSideBar } from '@/containers/app-side-bar';
import { AppProfile } from '@/containers/app-profile';
import { ShopsMap } from '@/containers/shops-map';
import { ProductOnMapButton } from '@/containers/products-on-map-button';
import { ActiveShopPanel } from '@/containers/active-shop-panel';
import { ActiveProductPanel } from '@/containers/active-product-panel';
import { nextWrapper } from '@/store/app';

const Shops: NextPage = () => {
  return (
    <>
      <ShopsMap />
      <ProductOnMapButton />
      <AppSideBar />
      <AppProfile />
      <ActiveShopPanel />
      <ActiveProductPanel />
    </>
  );
};

export const getServerSideProps = nextWrapper.getServerSideProps(
  mergeServerSideProps(
    getAuthServerSideProps,
    getCategoriesServerSideProps,
    getActiveShopServerSideProps,
    getActiveShopProductsServerSideProps,
  ),
);

export default Shops;
