import { useCallback, useEffect, useState, useRef } from 'react';
import {
  getAuth,
  signInWithPhoneNumber,
  RecaptchaVerifier,
  ConfirmationResult,
  UserCredential,
} from 'firebase/auth';

import { AppRoute } from '@/route/path';
import { useAppDispatch } from '@/store/app';
import { signOut } from '@/store/domains';
import { isBrowser } from '@/utils/is-browser';

import { useNavigate } from './use-navigate';

const useRecaptchaVerifier = () => {
  const recaptchaVerifierRef = useRef<RecaptchaVerifier | null>(null);

  const [timestamp, setTimestamp] = useState(new Date().toISOString());
  const onResetRecaptchaVerifier = useCallback(() => {
    setTimestamp(new Date().toISOString());
  }, []);

  useEffect(() => {
    if (isBrowser) {
      const container = document.createElement('button');
      container.style.display = 'none';
      document.body.appendChild(container);

      recaptchaVerifierRef.current = new RecaptchaVerifier(
        getAuth(),
        container,
        {
          size: 'invisible',
        },
      );

      return () => {
        document.body.removeChild(container);
      };
    }
  }, [timestamp]);

  return [recaptchaVerifierRef, onResetRecaptchaVerifier] as const;
};

export interface ISignInWithPhoneParams {
  onRequestConfirmationCode: () => void;
  onCodeConfirmed?: (result: UserCredential) => void;
}

export const useSignInWithPhone = ({
  onRequestConfirmationCode,
  onCodeConfirmed,
}: ISignInWithPhoneParams) => {
  const [isSigningIn, setIsSigningIn] = useState(false);
  const [signInError, setSignInError] = useState<Error | undefined>();

  const [isConfirming, setIsConfirming] = useState(false);
  const [confirmationError, setConfirmationError] = useState<
    Error | undefined
  >();

  const [recaptchaVerifierRef, onResetRecaptchaVerifier] =
    useRecaptchaVerifier();
  const confirmationResultRef = useRef<ConfirmationResult | null>(null);

  const onSignInWithPhone = useCallback(
    async (phone: string) => {
      if (confirmationResultRef.current) {
        setConfirmationError(undefined);
        onResetRecaptchaVerifier();
      }

      setIsSigningIn(true);

      try {
        const confirmationResult = await signInWithPhoneNumber(
          getAuth(),
          `+${phone}`,
          recaptchaVerifierRef.current!,
        );
        confirmationResultRef.current = confirmationResult;
        setSignInError(undefined);
        onRequestConfirmationCode();
      } catch (error) {
        setSignInError(error as Error);
      } finally {
        setIsSigningIn(false);
      }
    },
    [recaptchaVerifierRef, onResetRecaptchaVerifier, onRequestConfirmationCode],
  );

  const onConfirmCode = useCallback(
    (code: string) => {
      if (!confirmationResultRef.current) {
        return setConfirmationError(
          new Error('Confirmation result is not set!'),
        );
      }

      setIsConfirming(true);

      confirmationResultRef.current
        .confirm(code)
        .then((credentials) => {
          setConfirmationError(undefined);
          onCodeConfirmed?.(credentials);
        })
        .catch(setConfirmationError)
        .finally(() => setIsConfirming(false));
    },
    [onCodeConfirmed],
  );

  return {
    isSigningIn,
    signInError,
    onSignInWithPhone,

    isConfirming,
    confirmationError,
    onConfirmCode,
  };
};

export const useSignOut = () => {
  const dispatch = useAppDispatch();
  const navigateToShops = useNavigate(AppRoute.shops);

  return useCallback(() => {
    navigateToShops({ pick: [] });
    dispatch(signOut());
  }, [dispatch, navigateToShops]);
};
