import React, { useCallback, useEffect, useRef } from 'react';
import type { Bounds } from 'google-map-react';

import { useAppDispatch, useAppSelector } from '@/store/app';
import {
  // selectMapBounds,
  selectMapCoordinates,
  selectMapZoom,
  updateMapBounds,
  updateMapCoordinates,
  updateMapDistance,
  updateMapZoom,
} from '@/store/domains';
import { isBrowser } from '@/utils/is-browser';
import { getBrowserGeolocation } from '@/utils/geo';
import {
  DEFAULT_MAP_ZOOM_CITY,
  DEFAULT_MAP_ZOOM_COUNTRY,
  UKRAINE_COORDINATES,
} from '@/utils/constants';

import { MapScreen } from '../map-screen';

export function ShopsMap() {
  const dispatch = useAppDispatch();

  const mapCenter = useAppSelector(selectMapCoordinates);
  // const mapBounds = useAppSelector(selectMapBounds);
  const mapZoom = useAppSelector(selectMapZoom);

  const onBoundsChange = useCallback(
    (bounds: Bounds) => dispatch(updateMapBounds(bounds)),
    [dispatch],
  );
  const onCoordinatesChange = useCallback(
    (coordinates: ICoordinates) => dispatch(updateMapCoordinates(coordinates)),
    [dispatch],
  );
  const onDistanceChange = useCallback(
    (distance: number) => dispatch(updateMapDistance(distance)),
    [dispatch],
  );
  const onZoomChange = useCallback(
    (zoom: number) => dispatch(updateMapZoom(zoom)),
    [dispatch],
  );

  const preloadedMapZoom = useRef(mapZoom);
  const preloadedMapCenter = useRef(mapCenter);

  const mapCenterPreloaded = useRef(Boolean(mapCenter));
  useEffect(() => {
    if (mapCenterPreloaded.current || !shouldRequestBrowserGeolocation()) {
      return;
    }

    getBrowserGeolocation()
      .then((coordinates) => {
        onZoomChange(DEFAULT_MAP_ZOOM_CITY);
        onCoordinatesChange(coordinates);
      })
      .catch(() => {
        onZoomChange(DEFAULT_MAP_ZOOM_COUNTRY);
        onCoordinatesChange(UKRAINE_COORDINATES);
      });
  }, [onCoordinatesChange, onZoomChange]);

  return (
    <MapScreen
      zoom={mapZoom}
      // bounds={mapBounds}
      center={mapCenter}
      preloadedZoom={preloadedMapZoom.current}
      preloadedCenter={preloadedMapCenter.current}
      onZoomChange={onZoomChange}
      onBoundsChange={onBoundsChange}
      onDistanceChanged={onDistanceChange}
      onCoordinatesChange={onCoordinatesChange}
    />
  );
}

const shouldRequestBrowserGeolocation = () =>
  isBrowser && !new URLSearchParams(window.location.search).has('shopId');
