import React, { useCallback, useEffect, useRef } from 'react';
import { useTheme } from '@mui/material/styles';
import Menu, { MenuProps } from '@mui/material/Menu';
import MenuItem from '@mui/material/MenuItem';

import { iOSMobile, iOSMobileMenuTransitionDurationFix } from '@/utils/iOS';
import type { IShopClusterData } from '@/types/map';

export interface IShopMarkersMenuProps extends MenuProps {
  anchorEl?: Element | null;
  onMouseOut?: () => void;
  onCloseMenu?: () => void;
  activeShopId?: string;
  shops: Array<IShopClusterData>;
  onSelectShop: (shopId: string) => void;
}

const getCommonBoundingRect = (
  element1: Element,
  element2: Element,
  extendOn = 0,
) => {
  const rect1 = element1.getBoundingClientRect();
  const rect2 = element2.getBoundingClientRect();

  const x = Math.min(rect1.x, rect2.x) - extendOn;
  const y = Math.min(rect1.y, rect2.y) - extendOn;

  const width = Math.max(rect1.right, rect2.right) - x + extendOn;
  const height = Math.max(rect1.bottom, rect2.bottom) - y + extendOn;

  return new DOMRect(x, y, width, height);
};

const isEventInRect = (event: MouseEvent, rect: DOMRect) => {
  const { pageX: x, pageY: y } = event;
  return x >= rect.left && x <= rect.right && y >= rect.top && y <= rect.bottom;
};

export function ShopMarkersMenu({
  shops,
  onMouseOut,
  onCloseMenu,
  activeShopId,
  onSelectShop,
  ...props
}: IShopMarkersMenuProps) {
  const theme = useTheme();

  const createOnShopClick = (id: string) => () => {
    onSelectShop(id);
    onCloseMenu?.();
  };

  const menuListRef = useRef<HTMLUListElement>(null);
  const boundingRectRef = useRef<DOMRect | null>(null);

  useEffect(() => {
    const anchor = props.anchorEl;

    if (props.open && anchor) {
      const timerId = setTimeout(() => {
        boundingRectRef.current = getCommonBoundingRect(
          anchor,
          menuListRef.current!,
          parseInt(theme.spacing(1)),
        );
      }, theme.transitions.duration.enteringScreen);

      return () => {
        clearTimeout(timerId);
        boundingRectRef.current = null;
      };
    }

    return () => {
      boundingRectRef.current = null;
    };
  }, [props.open, props.anchorEl, theme]);

  const onGlobalMouseMove = useCallback(
    (e: MouseEvent) => {
      const rect = boundingRectRef.current;

      if (rect && !isEventInRect(e, rect)) {
        onMouseOut?.();
      }
    },
    [onMouseOut],
  );

  useEffect(() => {
    if (props.open) {
      window.addEventListener('mousemove', onGlobalMouseMove);
      return () => {
        window.removeEventListener('mousemove', onGlobalMouseMove);
      };
    }
  }, [props.open, onGlobalMouseMove]);

  return (
    <Menu
      {...props}
      sx={{ cursor: 'pointer' }}
      anchorOrigin={{ vertical: 'center', horizontal: 'right' }}
      transformOrigin={{
        vertical: 'center',
        horizontal: -parseInt(theme.spacing(1)),
      }}
      onBackdropClick={onCloseMenu}
      transitionDuration={
        iOSMobile
          ? iOSMobileMenuTransitionDurationFix
          : {
              enter: theme.transitions.duration.enteringScreen,
              appear: theme.transitions.duration.enteringScreen,
              exit: 0,
            }
      }
      MenuListProps={{
        ref: menuListRef,
        ...props.MenuListProps,
      }}
    >
      {shops?.map(({ id, name }) => (
        <MenuItem
          key={id}
          onClick={createOnShopClick(id)}
          selected={id === activeShopId}
        >
          {name}
        </MenuItem>
      ))}
    </Menu>
  );
}
