import React, { useCallback } from 'react';
import { useTheme, Theme } from '@mui/material/styles';
import Fab from '@mui/material/Fab';

import { toLatLng } from '@/utils/geo';
import type { ShopClusterPoint } from '@/types/map';

export interface IShopsClusterMarkerProps {
  cluster: ShopClusterPoint;
  map?: google.maps.Map;
  lat?: number;
  lng?: number;
}

export function ShopsClusterMarker({ map, cluster }: IShopsClusterMarkerProps) {
  const theme = useTheme();

  const onClick = useCallback(() => {
    if (!map) return;
    map.setZoom(map.getZoom()! + 1);
    map.panTo(toLatLng(cluster.geometry.coordinates));
  }, [cluster.geometry.coordinates, map]);

  const shopsCount = getShopsCountInCluster(cluster);
  const markerSize = getClusterMarkerSize(theme, shopsCount);

  return (
    <Fab
      color="secondary"
      sx={{
        width: markerSize,
        height: markerSize,
        color: theme.palette.secondary.main,
        backgroundColor: theme.palette.background.paper,
        ['&:hover']: {
          color: theme.palette.background.paper,
          backgroundColor: theme.palette.primary.main,
        },
      }}
      onClick={onClick}
    >
      {shopsCount}
    </Fab>
  );
}

const getShopsCountInCluster = (cluster: ShopClusterPoint): number => {
  return cluster.properties.point_count;
};

enum ClusterSize {
  s,
  m,
  l,
}

const getClusterSize = (count: number) => {
  if (count < 10) return ClusterSize.s;
  if (count < 50) return ClusterSize.m;
  return ClusterSize.l;
};

const clusterSizeMarkerSizeMap: Record<ClusterSize, number> = {
  [ClusterSize.s]: 6,
  [ClusterSize.m]: 8,
  [ClusterSize.l]: 10,
};

const getClusterMarkerSize = (theme: Theme, count: number) =>
  theme.spacing(clusterSizeMarkerSizeMap[getClusterSize(count)]);
